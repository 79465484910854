@import "../variables.modules";

nav {
  transition: $transition;
}

.active {
  border-bottom: 5px solid;
  border-image-slice: 1;
}

li {
  transition: $transition;

  &:hover {
    transform: translateY(-3px);
    transition: $transition;
  }
}

.initials {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-grow: 1;
}

.toggler {
  margin-left: auto;
}
